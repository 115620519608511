<template>
    <div>
        <rxNavBar title="上传信息"></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <van-uploader v-model="idCardFrontPhoto"
                      :before-read="frontBefore"
                      :after-read="frontAfter"
                      :preview-image="false"
                      :max-count="2">
            <img class="img" :src="frontPhoto">
        </van-uploader>
        <van-uploader v-model="idCardBackPhoto"
                      :before-read="backBefore"
                      :after-read="backAfter"
                      :preview-image="false"
                      :max-count="2">
            <img class="img" :src="backPhoto">
        </van-uploader>
        <van-uploader v-model="idCardInHandPhoto"
                      :before-read="inHandBefore"
                      :after-read="inHandAfter"
                      :preview-image="false"
                      :max-count="2">
            <img class="img" :src="inHandPhoto">
        </van-uploader>
        <van-button :disabled="!buttonEnable" class="bindButton_Enable"  @click="bind" >提交</van-button>
    </div>
</template>

<script>

    import {Uploader, NavBar, Button, Toast} from 'vant'
    import {base64Upload, queryBaseData, queryIdPhoto, saveUserPhoto} from "../../../getData/getData";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";

    export default {
        name: "UploadIDCard",
        data() {
            return{
                loadingFlag:false,//上传图片loading
                idCardFrontPhoto: [],
                frontPhoto: require('../../../assets/images/positive.png'),
                idCardBackPhoto: [],
                backPhoto: require('../../../assets/images/reverseSide.png'),
                idCardInHandPhoto: [],
                inHandPhoto: require('../../../assets/images/handheld.png')
            }
        },
        components: {
            [Uploader.name]: Uploader,
            [NavBar.name]: NavBar,
            [Button.name]: Button,
            rxNavBar,
        },
        computed: {
            buttonEnable () {
                return (this.idCardBackPhoto.length>0 && this.idCardFrontPhoto.length>0 && this.idCardInHandPhoto.length>0)
            }
        },
      created() {
        this.initData()
      },
      methods: {
          //初始化数据
          initData(){
            let that = this
            let initData = {
              user_id: globaluserId()
            }
            queryIdPhoto(initData).then(function (response) {
              responseUtil.dealResponse(that,response,()=>{
                console.log(response.data.data)
                if(response.data.data.FrontPhoto != undefined && response.data.data.FrontPhoto.path){
                  that.frontPhoto = response.data.data.FrontPhoto.path
                  that.idCardFrontPhoto.push({content:response.data.data.FrontPhoto.path})
                }
                if(response.data.data.BackPhoto != undefined && response.data.data.BackPhoto.path){
                  that.backPhoto = response.data.data.BackPhoto.path
                  that.idCardBackPhoto.push({content:response.data.data.BackPhoto.path})
                }
                if(response.data.data.InHandPhoto != undefined && response.data.data.InHandPhoto.path){
                  that.inHandPhoto = response.data.data.InHandPhoto.path
                  that.idCardInHandPhoto.push({content:response.data.data.InHandPhoto.path})
                }
              })

            })
          },
            leftReturn() {
                this.$router.go(-1)
            },
            frontBefore() {
                if(this.idCardFrontPhoto.length>0)
                    this.idCardFrontPhoto = []
                return true
            },
            frontAfter(file) {
                var that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.content = response.data.data.path
                    })
                })
                if(that.idCardFrontPhoto.length>0)
                    that.frontPhoto = that.idCardFrontPhoto[0].content
            },
            backBefore() {
                if(this.idCardBackPhoto.length>0)
                    this.idCardBackPhoto = []
                return true
            },
            backAfter(file) {
                var that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.content = response.data.data.path
                    })
                })
                if(that.idCardBackPhoto.length>0)
                    that.backPhoto = that.idCardBackPhoto[0].content
            },
            inHandBefore() {
                if(this.idCardInHandPhoto.length>0)
                    this.idCardInHandPhoto = []
                return true
            },
            inHandAfter(file) {
                var that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.content = response.data.data.path
                    })
                })
                if(that.idCardInHandPhoto.length>0)
                    that.inHandPhoto = that.idCardInHandPhoto[0].content
            },
            bind() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.idCardFrontPhoto = that.idCardFrontPhoto[0].content
                initData.idCardBackPhoto = that.idCardBackPhoto[0].content
                initData.idCardInHandPhoto = that.idCardInHandPhoto[0].content
                saveUserPhoto(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if(200==response.status){
                            that.$router.push('SetPersonalInfo')
                        }else {
                            Toast('认证失败')
                        }

                    })
                })
            }
        }
    }
</script>

<style scoped>

    .img{
        width: 260px;
        height: 164px;
        margin: 5px  58px;
        border-radius: 8px;
      object-fit: contain;
    }

    .bindButton_Enable{
        width: 345px;
        height: 45px;
        border-radius: 8px;
        font-size: 18px;
        color: white;
        line-height: 45px;
        text-align: center;
        margin: 8px 15px;
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
    }

</style>
